import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Card } from 'react-bootstrap';
import './tech.css';

import mysql from './../../assets/mysql.jpg';
import html from './../../assets/html.jpg';
import css from './../../assets/css.jpg';
import bootstrap from './../../assets/bootstarp.jpg';
import js from './../../assets/js.jpg';
import nodejs from './../../assets/nodejs.jpg';
import php from './../../assets/php.png';
import android from './../../assets/android.jpg';
import ios from './../../assets/ios.jpg';
import react from './../../assets/react.jpg';
import flutter from './../../assets/flutter.png';
import mongo from './../../assets/mongodb.jpg';
export default function Tech() {

    return (
        <section className="technology_section"><div className="container">
            <div className="col-sm-12 col-md-12 col-lg-12 tech_base ">
                <h1 className="head2 text-center ">Our Technologies</h1>
                <div className="text-center"><span className="underline"></span></div>
                <div className="col-sm-12 col-md-12 col-lg-12 tech_inner">
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={html} alt="html" />
                            <div className="tech-heading">Html</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={css} alt="html" />
                            <div className="tech-heading">Css</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={bootstrap} alt="html" />
                            <div className="tech-heading">Bootstrap</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={js} alt="html" />
                            <div className="tech-heading">Js</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={nodejs} alt="html" />
                            <div className="tech-heading">NodeJs</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={php} alt="html" />
                            <div className="tech-heading">PHP</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={android} alt="html" />
                            <div className="tech-heading">Android</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={ios} alt="html" />
                            <div className="tech-heading">IOS</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={flutter} alt="html" />
                            <div className="tech-heading">Flutter</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={react} alt="html" />
                            <div className="tech-heading">React</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={mysql} alt="html" />
                            <div className="tech-heading">Mysqk</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6 col-md-3 col-lg-2 techitem ">
                        <div className="col-sm-12 col-md-12 col-lg-12 boxshadow-boxwhite">
                            <img className="transition-zoom" src={mongo} alt="html" />
                            <div className="tech-heading">Mongo DB</div>
                        </div>
                    </div>
                </div>
            </div>
        </div></section>


    );
}

