import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './contact.css';
import map from './../../assets/map.svg';
import Layout from './../layout';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
const API_PATH = 'http://moondrumudichumatrimony.com/gmreact/api.php';
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

export default class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formstatus:0,
            loader:false,
            status:null,
            name: null,
            email: null,
            mobile: null,
            message: null,
            errors: {
                name: '',
                email: '',
                mobile: '',
                message: '',
            }
        };
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'name':
                errors.name =
                    value.length < 5
                        ? 'Name must be 5 characters long!'
                        : '';
                break;
            case 'message':
                errors.message =
                    value.length < 5
                        ? 'Please enter message!'
                        : '';
                break;
            case 'email':
                errors.email =
                    validEmailRegex.test(value)
                        ? ''
                        : 'Email is not valid!';
                break;
            case 'mobile':
                errors.mobile =
                    value.length === 0
                        ? 'Please enter mobile number'
                        : '';
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault(); console.log(this.state.errors);
        if (validateForm(this.state.errors)) {
            if (document.getElementById("name").value === '') {
                document.getElementById("name").focus();
            }
            else if (document.getElementById("email").value === '') {
                document.getElementById("email").focus();
            }
            else if (document.getElementById("mobile").value === '') {
                document.getElementById("mobile").focus();
            }
            else if (document.getElementById("message").value === '') {
                document.getElementById("message").focus();
            }
            else{
                this.setState({
                    loader:true
                });
                axios({
                    method: 'post',
                    url: `${API_PATH}`,
                    headers: { 'content-type': 'application/json' },
                    data: this.state
                  })
                    .then(result => {
                      this.setState({
                        loader:false,name:null,email:null,mobile:null,message:null,formstatus:0,
                        status:'Successfully your enquiry submitted. We will contact you shortly.'
                      });
                      document.getElementById("contact_form").reset();
                    })
                    .catch(error => this.setState({ status: error.message,loader:false,formstatus:1 }));
            }

        } else {
            console.error('Invalid Form')
        }
    }

    render() {
        const { errors } = this.state;
        return (<Layout><section><div className="container">
            <div className="col-sm-12 col-md-12 col-lg-12 banner_head ">
                <div className="col-sm-12 col-md-4 col-lg-4">
                    <h1 className="head2">Let's Talk</h1>
                    <p>Are you interested and would you like to speak to us? Start your next web project with us or Mobile Applications.</p>
                    <h1 className="sub_head">Email</h1>
                    <div className="contact_link"><a href="mailto:gmtechindia@gmail.com">gmtechindia@gmail.com</a></div>
                    <h1 className="sub_head">Mobile</h1>
                    <div className="contact_link"><a href="call:+917601863247">+91 7601863247</a></div>

                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 headquarters">
                    <h1 className="head2">Headquarters</h1>
                    <p>No:185 AMP Complex,<br />
        Near Vishnu Mahal, Walajapet,<br />
        Ranipet District,<br />
        Tamilnadu, India.<br />
                    </p>


                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 mapbox">
                    <h1 className="head2">Map</h1>
                    <a href="https://www.google.com/maps/place/GMTechindia/@12.9264353,79.2814307,12z/data=!4m16!1m10!4m9!1m1!4e2!1m6!1m2!1s0x3bad3511581ce4f1:0x5ac15679e85e84fe!2sgmtechindia!2m2!1d79.3514712!2d12.9264446!3m4!1s0x3bad3511581ce4f1:0x5ac15679e85e84fe!8m2!3d12.9264446!4d79.3514712" target="new"><div className="marker"><span>GM</span></div>
                    </a>
                    <img className=" img-responsive" src={map} alt="Gmtechindia Web & App development" />
                </div>
            </div></div></section>
            <section className="contact_form">
                <div className="col-sm-12 col-md-12 col-lg-12 contact_inner text-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                        <h1 className="head2 text-center ">Send us an email</h1>
                        <div className="text-center"><span className="underline"></span></div>
                        <p>Submit your enquires we always available to service you.</p>
                        <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                            <form onSubmit={this.handleSubmit} id="contact_form" noValidate>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group text-center inputbox">
                                    <input type="text" placeholder="Name" required onChange={this.handleChange} noValidate name="name" id="name" className="input-control" />
                                    {errors.name.length > 0 &&
                                        <span className='error'>{errors.name}</span>}
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group text-center inputbox">
                                    <input type="text" placeholder="Email" required onChange={this.handleChange} noValidate name="email" id="email" className="input-control" />
                                    {errors.email.length > 0 &&
                                        <span className='error'>{errors.email}</span>}
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group text-center inputbox">
                                    <input type="text" placeholder="Mobile" required onChange={this.handleChange} noValidate name="mobile" id="mobile" className="input-control" />
                                    {errors.mobile.length > 0 &&
                                        <span className='error'>{errors.mobile}</span>}
                                </div>

                                <div className="col-md-12 col-sm-12 col-xs-12 form-group inputbox">
                                    <textarea className="textarea-control  input-control" onChange={this.handleChange} noValidate required id="message" name="message" placeholder="Message"></textarea>
                                    {errors.message.length > 0 &&
                                        <span className='error'>{errors.message}</span>}
                                </div>
                                <div className="link col-md-12 col-sm-12 col-xs-12 inputbox">
                                   {this.state.loader===false?  <button className="theme_btn">Submit</button>:
                                   <button type="button" className="theme_btn"> <Spinner animation="grow" /></button>
                                   }
                                </div>
                                <div className= {"col-md-12 col-sm-12 col-xs-12"+(this.state.formstatus===1?' error':' success_message')}>
                                    {this.state.status!==null? this.state.status:''}
                                </div>
                            </form></div>

                    </div>

                </div>
            </section>
        </Layout>);
    }

}

