import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from 'react-bootstrap';
import {

    NavLink as Link
} from "react-router-dom";
//import './header.css';
import logo from './../../assets/logo.png';
export default function Header() {

    return (<div className="container headertop-container"><div className="col-md-12 col-sm-12 col-xs-12 col-lg-12 nopaddnew menu_base "><header className="main-header">
        {/*<Navbar bg="navbar-light" variant="navbar-light">
            <Navbar.Brand href="#home"> <img alt="GmtechIndia " className="head-navbar-img" src={logo} /></Navbar.Brand>
            <ul className="nav navbar-nav menu_links nopadd ml-auto">
                <li><Link exact={true} to="/">Home </Link></li>
                <li><Link to="/about">About </Link></li>
                <li><Link to="/services">Services </Link></li>
                <li><Link to="/contact">Contact  </Link></li>
            </ul>

</Navbar>*/}
        <Navbar collapseOnSelect expand="lg">
            <Navbar.Brand href="#"> <img alt="GmtechIndia " className="head-navbar-img" src={logo} /></Navbar.Brand>
            <div className="tabbtn"><Navbar.Toggle /></div>
            <Navbar.Collapse>
                <ul className="nav navbar-nav menu_links nopadd ml-auto">
                    <li className="nav-item"><Link className="nav-link" exact={true} to="/">Home </Link></li>
                    <li className="nav-item"><Link className="nav-link" to="/about">About </Link></li>
                    <li className="nav-item"><Link className="nav-link" to="/services">Services </Link></li>
                    <li className="nav-item"><Link className="nav-link" to="/contact">Contact  </Link></li>
                </ul>
            </Navbar.Collapse>
        </Navbar>

    </header>
    </div>
    </div>
    );
}

