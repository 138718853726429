import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Card } from 'react-bootstrap';
import './home.css';
import homebanner from './../../assets/blue_bg.png';
import banner from './../../assets/web_development_banner-min.png';
import web from './../../assets/web_dev_small-min.png';
import mobile from './../../assets/mobile-min.png';
import seo from './../../assets/seo-min.png';
import digital from './../../assets/digital-min.png';
import Layout from './../layout';

import Tech from './../tech/index';
import {

    NavLink as Link
} from "react-router-dom";
export default function Home() {

    return (<Layout><section>
      <div className="container-fluid p-0"> <div className="col-sm-12 col-md-12 col-lg-12 p-0 ">
        <img className="img-fluid"  src={homebanner} alt="Gmtechindia Web & App development" />
        </div>
        </div>
        <div className="container  ">
        
        <div className="col-sm-12 col-md-12 col-lg-12 banner_head ">
            <div className="col-sm-12 col-md-6 col-lg-6 banner_left">
                <h1 className="head1">Mobile Applications, Web design & development</h1>
                <p class="home-desc">A Website is the first mode of contact for business with respect to the customers. We design your dreams, so that they represent your business which is user and search engine friendly in the website designing process.
We are constantly coming up with new thoughts and new ideas in designing a website that satisfies the client requirements. Using solid expertise in mobile apps development, our team will help you create practical and seamless experiences on any device and get the best of mobile technology for your business.
</p>
                <Link className="theme_btn" to="/contact">Work with us!  </Link>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 banner_right">
                <img className="transition-zoom" src={banner} alt="Gmtechindia Web & App development" />
            </div>

        </div>


    </div></section>
        <section className="service_section "><div className="container">
            <div className="col-sm-12 col-md-12 col-lg-12 services_base ">
                <h1 className="head2 text-center ">Our Services</h1>
                <div className="text-center"><span className="underline"></span></div>
                <div className="col-sm-12 col-md-12 col-lg-12 service_inner">
                    <div className="col-sm-12 col-md-6 col-lg-6 text-center ">
                        <img className="transition-zoom" width="250" src={web} alt="Gmtechindia Web design & development" />
                        <h3 className="head3">Web</h3>
                        <p class="home-desc">Web development is the concept that encompasses all the activities involved with websites and web applications.</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 text-center ">
                        <img className="transition-zoom" width="250" src={mobile} alt="Gmtechindia Mobile App Development" />
                        <h3 className="head3">Mobile </h3>
                        <p class="home-desc">Mobile app development is quite a challenging job for mobile app developers to develop or port an application including varying operating system. </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 service_inner">  
                    <div className="col-sm-12 col-md-6 col-lg-6 text-center">
                        <img className="transition-zoom" width="250" src={seo} alt="Gmtechindia Web design & development" />
                        <h3 className="head3">Seo</h3>
                        <p class="home-desc">Our Search Engine Optimization (SEO) strategies and tactics can increase organic search visibility on Google and assist in leads for your company.</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 text-center">
                        <img width="250" className="transition-zoom" src={digital} alt="Gmtechindia Mobile App Development" />
                        <h3 className="head3"> Digital Marketing</h3>
                        <p class="home-desc">Our primary focus is to help businesses increase their client retention rate and maximize conversion opportunities..</p>
                    </div>
                </div>
            </div>
        </div></section>
        <section className="process_section"><div className="container">
            <div className="col-sm-12 col-md-12 col-lg-12 process_base ">
                <h1 className="head2 text-center ">Our Process</h1>
                <div className="text-center"><span className="underline"></span></div>
                <div className="col-sm-12 col-md-12 col-lg-12 process_inner">
                    <div className="col-sm-12 col-md-6 col-lg-6 process_left">
                        <h1 class="head5">How we work</h1>
                        <p class="home-desc">In Our organisation we used the Software Development Life Cycle, or SDLC  process to develop software. There are five different stages or phases within the software development life cycle and in each phase, different activities take place.</p>
                        <p class="home-desc">SDLC creates a structure for the development teams to be able to Requirement Analysis, Design, Development,Test and Launch in high quality software by defining various tasks that need to happen The life cycle defines a methodology for improving the quality of software and the overall development process.</p>
                        <p class="home-desc">The intent of a SDLC process it to help produce a product that is cost-efficient, effective, time to deliver and of high quality.</p>

                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 process_right">
                        <Accordion >
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    <h1 className="head5">Requirement Analysis</h1>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className="col-sm-12 col-md-12 col-lg-12 servicebox ">

                                            <p class="home-desc">The Software Development Life Cycle begins with requirement analysis phase, where the stakeholders discuss the requirements of the software that needs to be developed to achieve a goal. The aim of the requirement analysis phase is to capture the detail of each requirement and to make sure everyone understands the scope of the work and how each requirement is going to be fulfilled.</p>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    <h1 className="head5">Design</h1>
                                </Accordion.Toggle>
                                <Accordion.Collapse  eventKey="1">
                                    <Card.Body>
                                        <div className="col-sm-12 col-md-12 col-lg-12 servicebox ">

                                            <p class="home-desc">The next stage of SDLC is the Design phase. During the design phase, developers and technical architects start the high-level design of the software and system to be able to deliver each requirement.</p>
                                            
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    <h1 className="head5">Development</h1>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <div className="col-sm-12 col-md-12 col-lg-12 servicebox ">

                                            <p class="home-desc">This phase is obviously the hardest and potentially riskiest stage of the SDLC However, whether you’re working in Agile sprints, building out an MVP, or using the more traditional waterfall method, the goal here is to stick to the SOW, avoid scope creep, and build clean, efficient software.</p>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    <h1 className="head5">Testing</h1>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <div className="col-sm-12 col-md-12 col-lg-12 servicebox ">

                                            <p class="home-desc">Testing is the last phase of the software development life cycle before the software is delivered to customers. During testing, experienced testers start to test the system against the requirements.
This cycle is repeated until all requirements have been tested and all the defects have been fixed and the software is ready to be shipped.</p>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                    <h1 className="head5">Launch</h1>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <div className="col-sm-12 col-md-12 col-lg-12 servicebox ">

                                            <p class="home-desc">With the heavy lifting (and coding) out of the way, it’s time to launch your software to all of your users. What we’re talking about here is pushing your code into production. Not coming up with and implementing a go-to-market strategy (that’s more up to your sales and marketing teams).</p>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                    </div>
                </div>
            </div>
        </div></section>
        <Tech/>

    </Layout>
    );
}

