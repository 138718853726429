import React, { Suspense, lazy } from 'react';
import { HashRouter  as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './components/home';
import About from './components/about';
import Contact from './components/contact';
import Service from './components/service';
function App() {
  return (
    <Router >
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/about" component={About}/>
        <Route path="/contact" component={Contact}/>
        <Route path="/services" component={Service}/>
      </Switch>
    </Suspense>
  </Router>
  );
}

export default App;
