import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './service.css';
import logo from './../../assets/logo.png';
import Layout from './../layout';
import servicebanner from './../../assets/servicebanner_banner_right.png';
import web from './../../assets/web_dev_small-min.png';
import mobile from './../../assets/mobile-min.png';
import seo from './../../assets/seo-min.png';
import digital from './../../assets/digital-min.png';
import {
    NavLink as Link
} from "react-router-dom";
export default function Header() {

    return (<Layout>
        <section><div className="container">
            <div className="col-sm-12 col-md-12 col-lg-12 banner_head ">
                <div className="col-sm-12 col-md-6 col-lg-6 banner_left">
                    <h1 className="head1">Services We Offers</h1>
                    <p>We offers Web Design & Development, Mobile Applications, Wordpress Cms Website, Opencart Websites, Search Engine Optimizaion, Digital Marketting, E-Commerce Websites etc.</p>
                    <Link className="theme_btn" to="/contact">Work with us!  </Link>

                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 banner_right">
                    <img className="transition-zoom" src={servicebanner} alt="Gmtechindia Web & App development" />
                </div>
            </div></div></section>

         <section className="grey_section"><div className="container">
            <div className="col-sm-12 col-md-12 col-lg-12 grey_head ">
            <h1 className="head2 text-center ">Web Design & Development</h1>
                <div className="text-center"><span className="underline"></span></div>
                <div className="col-sm-12 col-md-12 col-lg-12 grey_container ">
                <div className="col-sm-12 col-md-6 col-lg-6 grey_left">
                   
                    <p>Gmtechindia is a full-service custom web design agency who specializes in designing websites that automatically attract the target audience of any business. For more than a decade, we have been serving businesses with user-centric designs with a combination of simplistic and alluring visuals. Our professional design team includes user-experience and conversion rate optimization specialists who can create awesome websites that not only look good but, simple to use for both you and your website visitors.</p>
                    <p>Gmtechindia is one of the best web development agencies that offer a huge variety of web development services. We work closely with our customers to create outstanding, high performing, and secure custom websites. Our expert developers have lots of experience to understand your project requirements and deliver a project that meets your needs. We will be happy in assisting you to offer a strong online presence.</p>
                    
                   
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 grey_right">
                    <img className="transition-zoom" src={web} alt="Gmtechindia Web & App development" />
                </div> </div>
            </div></div></section>  
            <section className="white_section"><div className="container">
            <div className="col-sm-12 col-md-12 col-lg-12 grey_head ">
            <h1 className="head2 text-center ">Mobile Applications Development</h1>
                <div className="text-center"><span className="underline"></span></div>
                <div className="col-sm-12 col-md-12 col-lg-12 grey_container ">
                <div className="col-sm-12 col-md-6 col-lg-6 grey_right">
                    <img className="transition-zoom" src={mobile} alt="Gmtechindia Web & App development" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 grey_left">
                   
                    <p>Gmtechindia is one of the best mobile app development company in India. Our dedicated team will provide you end-to-end solutions for all your mobile app requirement needs. We conduct extensive marketing research about clients, competitor’s strategy and industry leaders in order to develop a unique, leading, high-quality mobile app design.</p><p> We also deliver innovative solutions across various industries such as healthcare, travel & tourism, real estate and more. Our company has evolved into a one-stop destination for mobile application creation to the final steps of app marketing.</p>
                    
                </div>
                </div>
            </div></div></section>  
            <section className="grey_section"><div className="container">
            <div className="col-sm-12 col-md-12 col-lg-12 grey_head ">
            <h1 className="head2 text-center ">SEO & Digital Marketting</h1>
                <div className="text-center"><span className="underline"></span></div>
                <div className="col-sm-12 col-md-12 col-lg-12 grey_container ">
                <div className="col-sm-12 col-md-6 col-lg-6 grey_left">
                   
                    <p>Gmtechindia is a top digital marketing agency which specializes in offering data-driven and ROI-oriented digital marketing services. Our professional digital marketers take a customer-oriented approach to help your business reach its true potential.</p><p> Start a project with us today and we will provide you with the best internet marketing services that will completely transform your web presence into a lead generating empire.</p>
                   
                   
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 grey_right">
                    <img className="transition-zoom" src={seo} alt="Gmtechindia Web & App development" />
                </div> </div>
            </div></div></section>  
            <section className="about_section">
            <div className="col-sm-12 col-md-12 col-lg-12 about_inner text-center">
                <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                    <h1 className="head2 text-center ">Interest?</h1>
                    <div className="text-center"><span className="underline"></span></div>
                    <p>We prefer personal conversations.Try Gmtechindia or contact us.</p>
                    <div className="link">
                        <Link className="theme_btn" to="/contact">Contact  </Link>
                    </div>
                </div>

            </div>
        </section>
    </Layout>);
}

