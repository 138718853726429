import React,{useRef} from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './about.css';
import Layout from './../layout';
import aboutbanner from './../../assets/aboutbanner_banner_right.png';
import team from './../../assets/mob.webp';
import Tech from './../tech/index';
import {NavLink as Link
} from "react-router-dom";

export default function About() {let myRef = useRef();
   
    return (<Layout><section><div className="container">
        <div className="col-sm-12 col-md-12 col-lg-12 banner_head ">
            <div className="col-sm-12 col-md-6 col-lg-6 banner_left">
                <h1 className="head1">We are GMTechIndia</h1>
                <p>We are a leading software development company that offers top-rated Software Development Services with to our vast experience, team of skilled professionals, key business insights, and a dedicated working process.</p>
               <a className="theme_btn" href="" onClick={(e)=>{
                     e.preventDefault();                    
                     window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop })
                }} >Meet Our Team</a>
                
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 banner_right">
                <img className="transition-zoom" src={aboutbanner} alt="Gmtechindia Web & App development" />
            </div>
        </div></div></section>
        <section className="service_section" ref={myRef} id="team"><div className="container">
            <div className="col-sm-12 col-md-12 col-lg-12 team_base ">
                <h1 className="head2 text-center ">Our Team</h1>
                <div className="text-center"><span className="underline"></span></div>
                <div className="col-sm-12 col-md-12 col-lg-12 team_inner">
                    <div className="col-sm-12 col-md-12 col-lg-12 text-center ">
                        <p>We are a team of passionate web developers who share the vision of websites, that are high-performing, safe, and easy to maintain. We make this vision happen by building Progressive Mobile IOS & Android Apps and websites on the React and angular.  </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 team_inner">
                        <div className="col-sm-12 col-md-3 col-lg-3 text-center teamiteam">
                            <img className="transition-zoom" src={team} alt="Gmtechindia Web design & development" />
                            <h3 className="team_head">Narendiran G</h3>
                            <h3 className="head-title">FullStack Developer</h3>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 text-center teamiteam">
                            <img className="transition-zoom" src={team} alt="Gmtechindia Web design & development" />
                            <h3 className="team_head">Sivakumar M</h3>
                            <h3 className="head-title">FullStack Developer</h3>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 text-center teamiteam">
                            <img className="transition-zoom" src={team} alt="Gmtechindia Web design & development" />
                            <h3 className="team_head">Vidhya B</h3>
                            <h3 className="head-title">SEO & SMO</h3>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 text-center teamiteam">
                            <img className="transition-zoom" src={team} alt="Gmtechindia Web design & development" />
                            <h3 className="team_head">Naveen S</h3>
                            <h3 className="head-title">Graphic Designer </h3>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 text-center teamiteam">
                            <img className="transition-zoom" src={team} alt="Gmtechindia Web design & development" />
                            <h3 className="team_head">Rajesh R</h3>
                            <h3 className="head-title">UI/UX Designer </h3>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 text-center teamiteam">
                            <img className="transition-zoom" src={team} alt="Gmtechindia Web design & development" />
                            <h3 className="team_head">Tamil R</h3>
                            <h3 className="head-title">Graphic Designer </h3>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 text-center teamiteam">
                            <img className="transition-zoom" src={team} alt="Gmtechindia Web design & development" />
                            <h3 className="team_head">Nivedha</h3>
                            <h3 className="head-title">SEO & SMO </h3>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 text-center teamiteam">
                            <img className="transition-zoom" src={team} alt="Gmtechindia Web design & development" />
                            <h3 className="team_head">Logeshwaran S</h3>
                            <h3 className="head-title">PHP Developer </h3>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 text-center teamiteam">
                            <img className="transition-zoom" src={team} alt="Gmtechindia Web design & development" />
                            <h3 className="team_head">Thiyagarajan</h3>
                            <h3 className="head-title">Database Admin</h3>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 text-center teamiteam">
                            <img className="transition-zoom" src={team} alt="Gmtechindia Web design & development" />
                            <h3 className="team_head">Surya</h3>
                            <h3 className="head-title">Content Writer </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div></section>
        <section className="about_section">
            <div className="col-sm-12 col-md-12 col-lg-12 about_inner text-center">
                <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                    <h1 className="head2 text-center ">Interest?</h1>
                    <div className="text-center"><span className="underline"></span></div>
                    <p>We prefer personal conversations.Try Gmtechindia or contact us.</p>
                    <div className="link">
                        <Link className="theme_btn" to="/contact">Contact  </Link>
                    </div>
                </div>

            </div>
        </section>
        <Tech />

    </Layout>);
}

