import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {

    NavLink as Link
} from "react-router-dom";
//import './header.css';
import logo from './../../assets/logo_white.png';
export default function Footer() {

    return (<footer>
        {/* <div class="round_arrow_forword"><a href="/"  class="arrowtop_push">Arrpw</a></div> */}
        <section className="footer"><div className="container">
            <div className="col-sm-12 col-md-12 col-lg-12  ">
                <div className="col-sm-12 col-md-12 col-lg-12 footer_inner">
                    <div className="col-sm-12 col-md-6 col-lg-6 text-left ">
                        <div className="col-sm-12 col-md-10 col-lg-10 text-left nopaddnew">
                            <img className="transition-zoom img-responsive1" width="250" src={logo} alt="Gmtechindia Web design & development" />
                            <h3 className="head3">Find one innovative partner?</h3>
                            <Link className="theme_btn" to="/contact">Contact  </Link>
                            
                        </div></div>
                    <div className="col-sm-12 col-md-3 col-lg-3 text-left ">
                        <h3 className="head3">Company</h3>
                        <ul className="footer_links">
                            <li><Link to="/">Home </Link></li>
                            <li><Link to="/about">About </Link></li>
                            <li><Link to="/services">Services </Link></li>
                            <li><Link to="/contact">Contact  </Link></li>
                        </ul>

                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3 text-left">
                        <h3 className="head3">Contact</h3>
                        <ul className="footer_links">
                            <li><a href="mailto:gmtechindia@gmail.com">gmtechindia@gmail.com</a></li>
                            <li><a href="call:+917601863247">+91 7601863247</a></li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 text-left">
                        <p>© Gmtechindia.com - All rights reserved.</p>
                    </div>
                </div>

            </div>
        </div></section>

    </footer>);
}

